import React from "react"
import FotoRimac from "@components/pageCasosdeExito/image/imgPhotoEmpresa/rimac/FotoRimac.png"

//logo de empresas clientes
import Rimac from "@components/pageCasosdeExito/image/logoscustomer/Rimac.svg"

//iconos de modulos
import leads from "@components/pageCasosdeExito/image/iconcards/leads.svg"
import llamadas from "@components/pageCasosdeExito/image/iconcards/llamadas.svg"
import inbound from "@components/pageCasosdeExito/image/iconcards/inbound.svg"
import whatsapp from "@components/pageCasosdeExito/image/iconcards/whatsapp.svg"
import blending from "@components/pageCasosdeExito/image/iconcards/blending.svg"

const textintro = (
  <span className="">
    <span className="text-underlined-rimac">
      “Rimac brinda seguros con una cobertura total sin preocupaciones.
      <span className="strong-intro-casos">
        Ofrecen sus productos mediante Beex,
      </span>
      usando su versatilidad para atender a sus clientes por
      <span className="strong-intro-casos">
          canal telefónico o WhatsApp Business API.”
      </span>
    </span>
    <br />
    <span className="title-rimac">
      “Rimac brinda seguros con una cobertura total sin preocupaciones.
      <span className="strong-intro-casos">
        {" "}
        Ofrecen sus productos mediante Beex,
      </span>{" "}
      usando su versatilidad para atender a sus clientes por
      <span className="strong-intro-casos">
        {" "}
        canal telefónico o WhatsApp Business API
      </span>
      .”
    </span>
  </span>
)

const text = (
  <span>
    Rimac Seguros y Reaseguros es una de las mas importantes aseguradoras en
    Perú, pertenece al grupo Breca, que es un conglomerado empresarial que
    cuenta con más de 130 años de existencia y que tiene operaciones en Perú y
    en otros países de Latinoamérica.
    <br />
    <br />
    Ayudamos a sus operaciones de Telemarketing, Renovaciones y Pacientes
    Vulnerables, optimizando sus procesos al trabajar en conjunto para que
    nuestras plataformas tecnológicas sumen en sus resultados y objetivos.
    <br />
    <br />
    Con Beex, aplicamos los siguientes procesos que potencian su
    gestión diaria:
  </span>
)

const texttwo = (
  <span>
    “Con Rimac, nos une 3 años que compartimos servicios, estando felices y
    orgullosos de la sinergia creada y de los grandes equipos que hemos venido
    conociendo a lo largo del tiempo”
  </span>
)

const data = {
  intro: {
    image: Rimac,
    textbody: textintro,
    classlogo: "customerRimac",
    classrimac: "box-rimac",
  },
  description: {
    class: "section-descriptioncustomer",
    textbody: text,
    photo: FotoRimac,
  },
  cards: [
    {
      title: "Leads",
      img: leads,
      info:
        "Recepcionamos sus leads de diferentes hubs digitales para que sus teleoperadores de Telemarketing puedan ofrecer sus productos, y así mas personas se beneficien de sus planes de seguros y reaseguros.",
    },
    {
      title: "Llamadas",
      img: llamadas,
      info:
        "Disparamos llamadas automáticas y predictivas gracias a los métodos de propensión que viven y se configuran en Beex, respetando los scoring creados por sus equipos de Data Analytics y Digital, que están integrados en nuestras plataformas.",
    },
    {
      title: "Inbound",
      img: inbound,
      info:
        "Enrutamos las llamadas entrantes de sus clientes ante dudas o consultas de sus productos, respetando sus tiempos de atención, acorde a los niveles de servicio de sus diferentes negocios.",
    },
    {
      title: "WhatsApp",
      img: whatsapp,
      info:
        "Gestión conversacional de atención al cliente vía WhatsApp Business API, permitiendo que sus clientes conversen con Rimac mediante la App mas usada a nivel mundial, brindando enrutamiento inteligente de tickets, asignación de casos, derivaciones y mucho más.",
    },
    {
      title: "Blending",
      img: blending,
      info:
        "Atención de pacientes vulnerables en tiempos de Covid-19, permitiendo que enfermeros y médicos atiendan y ayuden a personas que deseen mas información para la prevención de este virus.",
    },
  ],
  descriptiontwo: {
    textbody: texttwo,
  },
}

export default data
